import { Spinner } from '@chakra-ui/react';
import Intercom, { show, showNewMessage } from '@intercom/messenger-js-sdk';
import { useAuthInfo } from '@propelauth/react';
import { captureException } from '@sentry/react';
import CryptoJS from 'crypto-js';
import { useDebounce } from 'hooks/useDebounce';
import { useOrg } from 'hooks/useOrg';
import { useCallback, useEffect, useRef, useState } from 'react';
import { IoMdHelp } from 'react-icons/io';
import { useSearchParams } from 'react-router-dom';

import { Button } from './ui/button';

const CLASSIFY_PRODUCTS_SUPPORT_MESSAGE = 'Need help with classifying products?';

export default function IntercomWidget({ support }: Readonly<{ support?: boolean }>) {
  const { user } = useAuthInfo();
  const { orgId } = useOrg();
  const [isIntercomLoading, setIsIntercomLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const needClassifyProdSupport = searchParams.get('needClassifyProdSupport') === 'true';
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const intercomFrameRef = useRef<HTMLElement | null>(null);

  const handleClick = useCallback(async () => {
    if (!user || !orgId) return;

    setIsIntercomLoading(true);

    const hash = CryptoJS.HmacSHA256(user.userId, import.meta.env.VITE_INTERCOM_SECRET_KEY).toString(CryptoJS.enc.Hex);

    try {
      await new Promise((resolve, reject) => {
        try {
          Intercom({
            custom_launcher_selector: support ? '#open_web_chat_support' : '#open_web_chat',
            app_id: import.meta.env.VITE_INTERCOM_APP_ID,
            user_id: user.userId,
            name: user.username,
            email: user.email,
            created_at: user.createdAt,
            Organization: orgId,
            user_hash: hash,
          });

          const timeoutId = setTimeout(() => {
            resolve(true);
          }, 500);

          return () => clearTimeout(timeoutId);
        } catch (innerError) {
          reject(innerError);
        }
      });

      if (needClassifyProdSupport) {
        showNewMessage(CLASSIFY_PRODUCTS_SUPPORT_MESSAGE);
      } else {
        show();
      }
    } catch (error) {
      console.error('Failed to initialize Intercom:', error);
      // Explicitly capture the error in Sentry
      captureException(error || new Error('Intercom initialization failed without message.'));
    } finally {
      setIsIntercomLoading(false);
    }
  }, [user, orgId, support, needClassifyProdSupport]);

  const debouncedHandleClick = useDebounce(handleClick);

  useEffect(() => {
    if (needClassifyProdSupport) {
      handleClick();
    }
  }, [needClassifyProdSupport, handleClick]);

  useEffect(() => {
    const checkIntercomFrame = () => {
      intercomFrameRef.current = document.getElementById('intercom-frame');
      setIsButtonVisible(!intercomFrameRef.current);
    };

    checkIntercomFrame();
    const observer = new MutationObserver(checkIntercomFrame);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  if (!isButtonVisible) {
    return null;
  }

  if (support) {
    return (
      <Button
        id="open_web_chat_support"
        onClick={debouncedHandleClick}
        px={4}
        py={4}
        h={'48px'}
        w={'200px'}
        fontSize={'18px'}
        fontWeight={500}
      >
        {isIntercomLoading && <Spinner />}
        Contact Support
      </Button>
    );
  }

  return (
    <Button
      id="open_web_chat"
      onClick={debouncedHandleClick}
      boxSize={'50px'}
      borderRadius={'full'}
      bgColor="#ffb000"
      _hover={{ bg: '#ffb000' }}
    >
      {isIntercomLoading && <Spinner />}
      <IoMdHelp size={'20px'} />
    </Button>
  );
}
